<template>
  <div class="pt40">
    <h3 class="ml40 mt20">Profile</h3>
    <div class="profile flex" :class="{ vertical: $mq == 'mobile' }">
      <div
        class="profile_left mr40 vertical flex px12"
        style="border-radius: 40px; width: 200px; height: 300px"
      >
        <div class="profile_photo mxauto mt12"></div>
        <h3 class="mt8" style="font-size: 16px; text-align: center">
          {{ $bus.user.name }}
        </h3>
        <p style="text-align: center; font-size: 14px">{{ $bus.user.type }}</p>
        <a
          @click="showChangePassword = !showChangePassword"
          class="mt12 mxauto pointer"
          style="font-size: 11px"
        >
          Change Password
        </a>
        <div class="profile_tab"></div>
        <div class="profile_tab"></div>
        <div class="profile_tab"></div>
      </div>
      <div v-if="showChangePassword">
        <div style="width: 350px" class="vertical flex">
          <span class="wfill mt12 vertical flex" style="bc-eee">
            <label class="mt4">New Password</label>
            <input
              class="mt0"
              type="password"
              v-model="resetPassword.new"
              placeholder="New Password"
            />
          </span>
          <span class="wfill mt12 vertical flex" style="bc-eee">
            <label class="mt4">Confirm New Password</label>
            <input
              class="mt0"
              type="password"
              v-model="resetPassword.confirm"
              placeholder="Confirm Password"
            />
          </span>
          <button @click="passwordReset" class="mt20 mxauto brown400">
            Reset Password
          </button>
          <button @click="showChangePassword = false" class="mt20 mxauto black">
            Back
          </button>
        </div>
      </div>
      <div v-else class="profile_right" :class="{ flex: $mq != 'mobile' }">
        <span style="width: 300px">
          <span class="vertical flex">
            <label class="mt4">Email</label>
            <input
              disabled
              class="mt0"
              type="text"
              v-model="form.email"
              placeholder="Your Email"
            />
          </span>
          <span class="vertical flex">
            <label class="mt4">Name</label>
            <input
              class="mt0"
              type="text"
              v-model="form.name"
              placeholder="Your Name"
            />
          </span>
          <span class="mt12 vertical flex">
            <label class="mt4">Contact</label>
            <input
              class="mt0"
              type="tel"
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              v-model="form.contact"
              placeholder="Contact No. (123-456-7890)"
            />
          </span>

          <!-- USER TYPE -->
          <span class="mt12 vertical flex">
            <label class="mt0">User Type</label>
            <select v-model="form.type" placeholder="User Type">
              <option selected disabled value="">User Type</option>
              <option v-for="t in userTypes" :key="t">{{ t }}</option>
            </select>
          </span>
          <span class="p12" style="bc-eee"> </span>
        </span>
        <span
          v-if="form.type == 'Student'"
          style="width: 300px"
          :class="{ ml24: $mq != 'mobile' }"
          class="ml0 mt0 vertical flex"
        >
          <!-- LEVEL OF EDUCATION -->
          <span class="vertical flex">
            <label class="mt4">Education Level</label>
            <select v-model="form.levelOfEducation">
              <option selected disabled value="">Level of Education</option>
              <option v-for="l in educationLevels" :key="l" :value="l">
                {{ l }}
              </option>
            </select>
          </span>
          <span
            class="mt12 vertical flex"
            v-if="
              !['Middle School', 'High School', ''].includes(
                form.levelOfEducation
              )
            "
          >
            <span class="vertical flex">
              <label>Field of Study</label>
              <select class="mt4" v-if="filteredFields" v-model="form.field">
                <option selected disabled value="">Field of Study</option>
                <option v-for="f in filteredFields" :key="f" :value="f">
                  {{ f }}
                </option>
              </select>
            </span>
            <span class="mt12 vertical flex">
              <label class="mt4">Year of Graduation</label>
              <select class="mt4 wfill" v-model="form.yearOfGraduation">
                <option selected disabled value="">Year of Graduation</option>
                <option v-for="y in years" :key="y" :value="y">{{ y }}</option>
              </select>
            </span>

            <label class="mt12">Country</label>
            <select :key="countries && countries.length" v-model="form.country">
              <option selected disabled value="">Select Country</option>
              <option v-for="(c, i) in countries" :key="i" :value="c">
                {{ c }}
              </option>
              <option value="Not Listed">Not Listed</option>
            </select>

            <span v-if="form.country == 'India'" class="mt12 vertical flex">
              <label class="mt4">Institute State</label>
              <select v-model="form.instituteState">
                <option value="" selected disabled>
                  Select Institute State
                </option>
                <option v-for="(state, i) in uniStates" :key="i" :value="state">
                  {{ state }}
                </option>
              </select>
            </span>

            <!-- INSTITUTE -->
            <span class="mt12 vertical flex">
              <label class="mt4">Institute</label>
              <select v-model="form.institute">
                <option value="" selected disabled>Select Institute</option>
                <option value="Not Listed">Not Listed</option>
                <option
                  v-for="(u, i) in filteredUniversities"
                  :key="i"
                  :value="u"
                >
                  {{ u }}
                </option>
              </select>
            </span>
            <label v-if="form.institute == 'Not Listed'" class="mt12"
              >Institute Name</label
            >
            <input
              placeholder="Enter Institute Name"
              v-if="form.institute == 'Not Listed'"
              :value="form.notListedInstitute"
              @change="form.notListedInstitute = $event.target.value"
            />
            <button
              class="mt12 fs12"
              v-if="redirectFrom"
              @click="
                $router.push('/ideace/' + redirectFrom + '?preregister=true')
              "
            >
              Submit & Complete Event Registration
            </button>
          </span>
        </span>
        <span
          v-if="form.type == 'Professional'"
          style="width: 300px"
          class="ml24 mt0 vertical flex"
        >
          <!-- FIELD OF STUDY -->
          <label>Employment Status</label>
          <select v-model="form.employmentStatus">
            <option selected disabled value="">Employment Status</option>
            <option v-for="f in employmentStatuses" :key="f" :value="f">
              {{ f }}
            </option>
          </select>

          <span
            class="mt12 vertical flex"
            v-if="['Employed', 'Self Employed'].includes(form.employmentStatus)"
          >
            <label>Field of Work</label>
            <select v-model="form.field">
              <option selected disabled value="">Field of Work</option>
              <option v-for="f in fields['Undergraduate']" :key="f" :value="f">
                {{ f }}
              </option>
            </select>

            <label class="mt12">Company Name</label>
            <input
              type="text"
              v-model="form.companyName"
              placeholder="Company Name"
            />

            <label class="mt12">Country</label>
            <select :key="countries.length" v-model="form.country">
              <option selected disabled value="">Select Country</option>
              <option v-for="(c, i) in countries" :key="i" :value="c">
                {{ c }}
              </option>
              <option value="Not Listed">Not Listed</option>
            </select>

            <label v-if="form.country == 'India'" class="mt12"
              >Work Location</label
            >
            <select
              v-if="form.country == 'India'"
              :key="cities.length"
              v-model="form.location"
            >
              <option selected disabled value="">Select City</option>
              <option v-for="(c, i) in sortedCities" :key="i" :value="c">
                {{ c }}
              </option>
              <option value="Not Listed">Not Listed</option>
            </select>
            <button
              class="mt12 fs12"
              v-if="redirectFrom"
              @click="
                $router.push('/ideace/' + redirectFrom + '?preregister=true')
              "
            >
              Submit & Complete Event Registration
            </button>
          </span>
        </span>
        <span
          v-if="form.type == 'Faculty'"
          style="width: 300px"
          class="ml24 mt0 vertical flex"
        >
          <!-- FIELD OF STUDY -->
          <label for="">Teaching Field</label>
          <select v-model="form.field">
            <option selected disabled value="">Field</option>
            <option v-for="f in fields['Undergraduate']" :key="f" :value="f">
              {{ f }}
            </option>
          </select>

          <label class="mt12">Country</label>
          <select :key="countries.length" v-model="form.country">
            <option selected disabled value="">Select Country</option>
            <option v-for="(c, i) in countries" :key="i" :value="c">
              {{ c }}
            </option>
            <option value="Not Listed">Not Listed</option>
          </select>

          <span v-if="form.country == 'India'" class="mt12 vertical flex">
            <label class="mt4">Institute State</label>
            <select v-model="form.instituteState">
              <option value="" selected disabled>Select Institute State</option>
              <option v-for="(state, i) in uniStates" :key="i" :value="state">
                {{ state }}
              </option>
            </select>
          </span>

          <!-- INSTITUTE -->
          <span v-if="form.country == 'India'" class="mt12 vertical flex">
            <label class="mt4">Institute</label>
            <select v-model="form.institute">
              <option value="" selected disabled>Select Institute</option>
              <option value="Not Listed">Not Listed</option>
              <option
                v-for="(u, i) in filteredUniversities"
                :key="i"
                :value="u"
              >
                {{ u }}
              </option>
            </select>
          </span>

          <label v-if="form.institute == 'Not Listed'" class="mt12"
            >Institute Name</label
          >
          <input
            placeholder="Enter Institute Name"
            v-if="form.institute == 'Not Listed'"
            :value="form.notListedInstitute"
            @change="form.notListedInstitute = $event.target.value"
          />
          <button
            class="mt12 fs12"
            v-if="redirectFrom"
            @click="
              $router.push('/ideace/' + redirectFrom + '?preregister=true')
            "
          >
            Submit & Complete Event Registration
          </button>
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="css">
.profile {
  padding: 20px;
}
</style>

<script>
import st from "storerestapi";
import moment from "moment";
export default {
  name: "Profile",
  data() {
    return {
      first: "",
      last: "",
      email: "",
      password: "",
      passwordCheck: "",
      resetCode: "",
      dbUnis: [],
      cities: [],
      uniState: "",
      countries: [
        "Afghanistan",
        "Albania",
        "Algeria",
        "Andorra",
        "Angola",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Australia",
        "Austria",
        "Azerbaijan",
        "The Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bhutan",
        "Bolivia",
        "Bosnia and Herzegovina",
        "Botswana",
        "Brazil",
        "Brunei",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cabo Verde",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Central African Republic",
        "Chad",
        "Chile",
        "China",
        "Colombia",
        "Comoros",
        "Congo, Democratic Republic of the",
        "Congo, Republic of the",
        "Costa Rica",
        "Côte d’Ivoire",
        "Croatia",
        "Cuba",
        "Cyprus",
        "Czech Republic",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "East Timor (Timor-Leste)",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Eswatini",
        "Ethiopia",
        "Fiji",
        "Finland",
        "France",
        "Gabon",
        "The Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Greece",
        "Grenada",
        "Guatemala",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Honduras",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Korea, North",
        "Korea, South",
        "Kosovo",
        "Kuwait",
        "Kyrgyzstan",
        "Laos",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands",
        "Mauritania",
        "Mauritius",
        "Mexico",
        "Micronesia",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Morocco",
        "Mozambique",
        "Myanmar ",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "North Macedonia",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Poland",
        "Portugal",
        "Qatar",
        "Romania",
        "Russia",
        "Rwanda",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome and Principe",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "Spain",
        "Sri Lanka",
        "Sudan",
        "Sudan, South",
        "Suriname",
        "Sweden",
        "Switzerland",
        "Syria",
        "Taiwan",
        "Tajikistan",
        "Tanzania",
        "Thailand",
        "Togo",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "United Arab Emirates",
        "United Kingdom",
        "United States",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Vatican City",
        "Venezuela",
        "Vietnam",
        "Yemen",
        "Zambia",
        "Zimbabwe",
      ],
      uniStates: [
        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chhattisgarh",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jammu & Kashmir",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Orissa",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Tripura",
        "Uttarakhand",
        "Uttar Pradesh",
        "West Bengal",
        "Andaman and Nicobar Islands",
        "Chandigarh",
        "Dadra & Nagar Haveli",
        "Daman & Diu",
        "New Delhi",
        "Lakshadweep",
        "Puducherry",
        "Telengana",
      ],
      fields: {
        Diploma: [
          "Architecture",
          "Interior Design",
          "Civil Engineering",
          "Planning",
          "Design",
          "Other",
        ],
        Undergraduate: [
          "Architecture",
          "Interior Design",
          "Civil Engineering",
          "Planning",
          "Design",
          "Other",
        ],
        Postgraduate: [
          "Architecture",
          "Urban Design",
          "Urban Planning",
          "Landscape",
          "Building Engineering Management",
          "Construction Management",
          "Interior Design",
          "Conservation",
          "Product Design",
          "Housing",
          "Transportation Design",
          "Other",
        ],
      },
      form: {
        email: this.$bus.user.email,
        name: this.$bus.user.name,
        yearOfGraduation: this.$bus.user.yearOfGraduation,
        institute: this.$bus.user.institute,
        notListedInstitute: this.$bus.user.notListedInstitute,
        field: this.$bus.user.field,
        contact: this.$bus.user.contact,
        country: this.$bus.user.country,
        levelOfEducation: this.$bus.user.levelOfEducation,
        type: this.$bus.user.type,
        companyName: this.$bus.user.companyName,
        location: this.$bus.user.location,
        employmentStatus: this.$bus.user.employmentStatus,
        instituteState: this.$bus.user.instituteState,
        university:
          (this.$bus.user.university || {}).name +
          ", " +
          (this.$bus.user.university || {}).city,
      },
      educationLevels: [
        "Middle School",
        "High School",
        "Diploma",
        "Undergraduate",
        "Postgraduate",
        "Doctorate",
      ],
      employmentStatuses: ["Employed", "Self Employed", "None"],
      states: ["login", "signup", "forgotPassword", "resetPassword"],
      showChangePassword: false,
      resetPassword: { old: "", new: "", confirm: "" },
      userTypes: ["Student", "Professional", "Faculty"],
      redirectFrom: "",
    };
  },
  mounted() {
    this.redirectFrom = this.$route.query.redirectFrom;
    this.$req
      .get("universities?_limit=-1")
      .then((r) => {
        this.dbUnis = r.data;
        this.cities = r.data
          .map((u) => u.city)
          .filter((v, i, s) => s.indexOf(v) == i)
          .sort();
      })
      .catch((e) => console.log(e));
  },
  watch: {
    form: {
      handler: function (v) {
        this.$req
          .post(
            `updateUser?id=${this.$bus.user.id}`,
            {
              ...v,
              updater: this.$bus.user.id,
            },
            {
              headers: {
                Authorization: `Bearer ${this.$bus.token}`,
              },
            }
          )
          .then(() => {
            this.$bus.user = {
              ...this.$bus.user,
              ...v,
            };
            st.setVar("user", this.$bus.user);
            this.$bus.notify("Profile Updated");
          })
          .catch((e) => console.log(e));
      },
      deep: true,
    },
  },
  methods: {
    passwordReset: function () {
      if (this.resetPassword.new != this.resetPassword.confirm) {
        alert("Passwords don't match.");
        return;
      }
      this.$req
        .post(
          `resetPasswordFromProfile?token=${this.$bus.token}&password=${this.resetPassword.new}`,
          {},
          this.$bus.headers
        )
        .then(() => {
          alert("Password reset");
        })
        .catch((e) => console.log(e));
    },
  },
  computed: {
    years: function () {
      if (
        ["Middle School", "High School", ""].includes(
          this.form.levelOfEducation
        )
      ) {
        return new Array(70).fill(1).map((n, i) => {
          return 1960 + i;
        });
      } else {
        return new Array(16).fill(1).map((n, i) => {
          return parseInt(moment().subtract(8, "years").format("YYYY")) + i;
        });
      }
    },
    registrationsClosed: function () {
      let route = this.$route.query.ethos;
      let fourteenth = moment("16 Sep", "DD MMM");
      if (!route) {
        return fourteenth.diff(moment(), "days") > 0;
      }
      return false;
    },
    sortedCities: function () {
      return this.cities;
    },
    filteredUniversities: function () {
      try {
        let key = {
          Architecture: "#a",
          "Interior Design": "#d",
          Planning: "#p",
          "Civil Engineering": "#c",
          Design: "#d",
          "": "",
        };
        var unis;
        var field = this.form ? this.form.field : "";
        if (Object.keys(key).includes(field)) {
          unis = this.dbUnis
            .filter((u) => {
              if (u.categories) {
                return u.categories.includes(key[field] || "");
              }
              return false;
            })
            .filter((u) =>
              this.form && this.form.country && this.form.country == "India"
                ? u.state == this.form.instituteState
                : true
            )
            .map((u) => u?.name + ", " + u?.city);
        } else {
          unis = this.dbUnis;
        }
        // if (['Architecture', 'Interior Design'].includes(this.form.field)) {
        //   var unis = this.dbUnis.map(u => u.name + ', ' + u.city)
        // } else {
        //   unis = this.universities
        // }
        unis = [...unis];
        return unis.sort();
      } catch (e) {
        return [];
      }
    },
    filteredFields: function () {
      if (this.fields[this.form.levelOfEducation]) {
        return this.fields[this.form.levelOfEducation];
      }
      return false;
    },
  },
};
</script>

<style lang="css" scoped>
.profile_photo {
  width: 100px;
  height: 100px;
  background: url("https://upload.wikimedia.org/wikipedia/commons/a/ad/Placeholder_no_text.svg")
    center / cover no-repeat;
  border-radius: 100px;
}
.profile_left {
  border: 1px solid #ccc;
  border-radius: 4px;
}
.profile_picture {
  width: 100px;
  height: 100px;
}
</style>
